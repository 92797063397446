import React from "react"
import styled from "styled-components";
import ContainerSmall from "../styles/ContainerSmall";
import StyledH1 from "../styles/StyledH1";


const TextPageHeaderWrapper = styled(ContainerSmall)`

  p {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 150%;
    color: #141414;
  }
`;


const TextPageHeader = (props) => {
  return (
    <TextPageHeaderWrapper>
     <StyledH1>{props.title}</StyledH1>
      <p>{props.text}</p>
    </TextPageHeaderWrapper>
  )
}

export default TextPageHeader;
