import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import TextPageHeader from "../components/TextPageHeader";
import ContainerSmall from "../styles/ContainerSmall";
import ResponsiveEmbed from "react-responsive-embed";

import styled from "styled-components";

const VideoWrapper = styled(ContainerSmall)`

 padding-bottom: 40px;
`

const feature = (props) =>{


  const post = props.data.markdownRemark;


  let vimeoUrl;
  let iframe;

  if(post.frontmatter.vimeoid) {
    vimeoUrl = `https://player.vimeo.com/video/${post.frontmatter.vimeoid}`
    iframe = <ResponsiveEmbed src={vimeoUrl} allowfullscreen/>
  }



  return(
    <Layout location={props.location} grayFooter={true}>


      <TextPageHeader
        title={post.frontmatter.title}
        text={post.frontmatter.teaser}
      />
      <ContainerSmall>
        <p>{post.frontmatter.description}</p>
      </ContainerSmall>

      <VideoWrapper>
        {iframe}
      </VideoWrapper>

      <ContainerSmall dangerouslySetInnerHTML={{__html: post.html}}/>


    </Layout>
  )
}

export default feature;

export const Head = (props) => {

  return (
    <Seo
      title={props.data.markdownRemark.frontmatter.title}
      description={props.data.markdownRemark.frontmatter.description}
      canonical={props.location.pathname}
    />
  )
}


export const pageQuery = graphql`
  query FullFeatureBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        path
        description
        vimeoid
        teaser
        tag
      }
    }
  }
`
