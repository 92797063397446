import styled from "styled-components";

const StyledH1 = styled.h1`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 3.25rem;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #141414;
    @media(max-width: ${props => props.theme.screenSmMax}) {
     font-size: 2.2rem;
    }
`;

export default StyledH1;
